
export enum CustomBadgeStyle {
  LANDING = 'landing',
  STAGING = 'staging',
  WRANGLING = 'wrangling',
  MARTS = 'marts',
  SNOWFLAKE = 'snowflake',
  MYSQL = 'mysql',
  MSSQL = 'mssql',
  DBT = 'dbt',
  WATERMARK = 'watermark',
  PRIMARY_KEY = 'primarykey',
  FOREIGN_KEY = 'foreignkey',
  UNIQUE = 'unique'
}
