// This file should be used to add new config variables or overwrite defaults from config-default.ts

import { AppConfigCustom } from './config-types';

import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';

const configHost: AppConfigCustom = {
  logoPath: '/static/images/cmdrvl-logo.png',
  logoTitle: 'CMD+RVL - Data Discovery Portal',
  browse: {
    curatedTags: ['cmbs', 'annex', 'conduit', 'wfcm2022c62', 'bank2022bnk43'],
    showAllTags: false,
    showBadgesInHome: true,
    hideNonClickableBadges: false
  },
  indexDashboards: {
    enabled: true,
  },
  indexUsers: {
    enabled: false,
  },
  preview: {
    enabled: false,
  },
  analytics: {
    plugins: [
      googleAnalytics({
        measurementIds: ['G-BN5MP4PVK1'],
        sampleRate: 100
      }),
      googleTagManager({
        containerId: 'GT-NSSMXSG'
      })
    ],
  },
  navLinks: [
    {
      label: 'Announcements',
      id: 'nav::announcements',
      href: '/announcements',
      use_router: true,
    },
    {
      label: 'Browse',
      id: 'nav::browse',
      href: '/browse',
      use_router: true,
    },
  ],
  bookmarks: {
    enabled: false,
  },
  homePageWidgets: {
    widgets: [
      {
        name: 'SearchBarWidget',
        options: {
          path: 'SearchBarWidget/index',
        },
      },
      {
        name: 'BadgesWidget',
        options: {
          additionalProps: {
            shortBadgesList: true,
          },
          path: 'BadgesWidget/index',
        },
      },
      {
        name: 'TagsWidget',
        options: {
          additionalProps: {
            shortTagsList: true,
          },
          path: 'TagsWidget/index',
        },
      }
    ],
  },
  tableLineage: {
    defaultLineageDepth: 25,
    inAppListEnabled: false,
    inAppPageEnabled: false,
    externalEnabled: false,
    iconPath: 'PATH_TO_ICON',
    isBeta: false,
    urlGenerator: (
      database: string,
      cluster: string,
      schema: string,
      table: string
    ) =>
      `https://DEFAULT_LINEAGE_URL?schema=${schema}&cluster=${cluster}&db=${database}&table=${table}`,
  }
};

export default configHost;
